/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Modal,
  ModalBody,
  Form
} from "reactstrap"
import DatatableTables from "components/tables/DatatableTables";
import { PANELUSERS } from 'helpers/url_helper'
import { post, get, del } from 'helpers/api_helper'
import Swal from 'sweetalert2';

const formObj = {
  id: '',
  username: '',
  code: '',
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
}

const PanelUsers = () => {
  const [loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [submitBtnActive, setSubmitBtnActive] = useState(false);
  const [formData, setFormData] = useState(formObj);
    
  const columns = [{
    dataField: 'sr',
    text: '#',
    sort: true,
    formatter: (cell, row, index) => `${index +1}`
  }, {
    dataField: 'username',
    text: 'Username',
    sort: true
  }, {
    dataField: 'name',
    text: 'FullName',
    sort: true,
    formatter: (cell, row) => `${row.firstname} ${row.lastname}`
  }, {
    dataField: 'code',
    text: 'User ID',
    sort: true
  }, {
    dataField: 'phone',
    text: 'Contact',
    sort: true
  }, {
    dataField: 'email',
    text: 'Email',
    sort: true
  }, {
    dataField: 'actions',
    text: 'Actions',
    formatter: (cell, row) => {
      return (
        <>
          <button onClick={() => editRecord(row)} className="btn btn-primary">
            Edit
          </button>
          {' '}
          <button onClick={() => removeRecord(row)} className="btn btn-danger">
            Delete
          </button>
        </>
      );
    },
  }];

  const fetchData = async () => {
    setLoading(true);
    const res = await get(`${PANELUSERS}`);
    if(res?.success) {
      setUsersData(res?.body)
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  const addRecord = async (data) => {
    setSubmitBtnActive(true);
    console.log(data)
    // const res = await post(PANELUSERS, data);
    // if(res.success){
    //   fetchData()
    //   setFormData(formObj)
    //   setSubmitBtnActive(false);
    //   setModalOpen(false);
    // }
  }

  const editRecord = async (data) => {
    Swal.fire({
      title: "Currently Not Allowed!",
      text: "",
      icon: "warning"
    });
  }

  const removeRecord = async (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      preConfirm: async () => {
        try {
          const res = await del(`${PANELUSERS}/${data.code}`);
          if (res.body === 1) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
          } else {
            throw new Error('Delete failed');
          }
        } catch (error) {
          Swal.fire(
            'Error!',
            'There was a problem deleting your file.',
            'error'
          );
        }
      }
    });
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Panel Users | Advance Dental Export Canteen Management</title>
        </MetaTags>
        <Container fluid>
          <DatatableTables addBtnLabel={"Panel Users"} title={"Panel Users"} columns={columns} rows={usersData} callback={() => setModalOpen(true)} />

           
          <Modal
            isOpen={modalOpen}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="modal-title">Panel user</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModalOpen(false)}><span aria-hidden="true">×</span></button>
            </div>
            <Form
              onSubmit={(v) => {
                v.preventDefault();
                addRecord({
                  ...formData,
                  username: v.target.username.value,
                  firstname: v.target.firstname.value,
                  lastname: v.target.lastname.value,
                  code: v.target.code.value,
                  email: v.target.email.value,
                  phone: v.target.phone.value
                })
              }}
            >
              <ModalBody>
                <div className="row mb-3">
                  <div className="col-8">
                    <label htmlFor="username" className="form-label imp">Username</label>
                    <input name="username" id="username" placeholder="Enter Username" required type="text" className="form-control" />
                  </div>
                  <div className="col-4">
                    <label htmlFor="code" className="form-label imp">Code</label>
                    <input name="code" id="code" placeholder="Enter User Code" required type="text" className="form-control" />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-6">
                    <label htmlFor="firstname" className="form-label imp">First Name</label>
                    <input name="firstname" id="firstname" placeholder="Enter First name" required type="text" className="form-control" />
                  </div>
                  <div className="col-6">
                    <label htmlFor="lastname" className="form-label imp">Last Name</label>
                    <input name="lastname" id="lastname" placeholder="Enter last name" required type="text" className="form-control" />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-6">
                    <label htmlFor="email" className="form-label">Email Address</label>
                    <input name="email" id="email" placeholder="Enter First name" type="text" className="form-control" />
                  </div>
                  <div className="col-6">
                    <label htmlFor="phone" className="form-label">Contact Number</label>
                    <input name="phone" id="phone" placeholder="Enter Contact Number" type="text" className="form-control" />
                  </div>
                </div>
                
              </ModalBody>
              <div className="modal-footer">
                <button type="button" className="btn btn-light" onClick={() => setModalOpen(false)}>Close</button>
                <button type="submit" className="btn btn-primary" disabled={submitBtnActive}>Submit</button>
              </div>
            </Form>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PanelUsers;
