import React from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import PropTypes from 'prop-types';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import "./datatables.scss"

const DatatableTables = ({title, columns=[], rows=[], addBtnLabel='', isAdd=false, callback = ()=>{}}) => {
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: rows.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (rows).length }
  ];

  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              {/* <p className="card-title-desc">
                react-bootstrap-table-next plugin has most features enabled by default,
                so all you need to do to use it with your own tables is to
                call the construction function:{" "}
                <code>react-bootstrap-table-next </code>.
              </p> */}

              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField='id'
                columns={columns}
                data={rows}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField='id'
                    columns={columns}
                    data={rows}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col md="6">
                            <h4 className="h4">{title}</h4>
                          </Col>
                          <Col md="6">
                            <div className="d-flex justify-content-end">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                              <div>
                                {
                                  !isAdd && 
                                  <Button className="btn btn-success" onClick={callback}><i className="fas fa-plus"></i> {addBtnLabel}</Button>
                                }
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={
                                  "table align-middle table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />

                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                    }
                  </ToolkitProvider>
                )
                }</PaginationProvider>

            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

DatatableTables.propTypes = {
  title: PropTypes.any,
  columns: PropTypes.any,
  rows: PropTypes.any,
  addBtnLabel: PropTypes.any,
  callback: PropTypes.any,
  isAdd: PropTypes.any,
}
export default DatatableTables
