/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Modal,
  ModalBody,
  Form
} from "reactstrap"
import DatatableTables from "components/tables/DatatableTables";
import { CANTEEN } from 'helpers/url_helper'
import { post, get, del } from 'helpers/api_helper'
import Swal from 'sweetalert2';

const formObj = {
  id: '',
  title: '',
  code: '',
}

const Canteens = () => {
  const [loading, setLoading] = useState(false);
  const [canteenData, setCanteenData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [submitBtnActive, setSubmitBtnActive] = useState(false);
  const [formData, setFormData] = useState(formObj);
  
  const columns = [{
    dataField: 'sr',
    text: '#',
    sort: true,
  }, {
    dataField: 'username',
    text: 'Username',
    sort: true
  }, {
    dataField: 'title',
    text: 'Canteen Name',
    sort: true
  }, {
    dataField: 'code',
    text: 'Canteen Code',
    sort: true
  }, {
    dataField: 'location',
    text: 'Location',
    sort: true
  }, {
    dataField: 'actions',
    text: 'Actions',
    formatter: (cell, row) => {
      return (
        <>
          <button onClick={() => editRecord(row)} className="btn btn-primary">
            Edit
          </button>
          {' '}
          <button onClick={() => removeRecord(row)} className="btn btn-danger">
            Delete
          </button>
        </>
      );
    },
  }];

  const fetchData = async () => {
    setLoading(true);
    const res = await get(`${CANTEEN}`);
    if(res?.success) {
      setCanteenData(res?.body)
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  const addRecord = async (data) => {
    setSubmitBtnActive(true);
    const res = await post(CANTEEN, data);
    if(res.success){
      fetchData()
      setFormData(formObj)
      setSubmitBtnActive(false);
      setModalOpen(false);
    }
  }

  const editRecord = async (data) => {
    Swal.fire({
      title: "Currently Not Allowed!",
      text: "",
      icon: "warning"
    });
  }

  const removeRecord = async (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      preConfirm: async () => {
        try {
          const res = await del(`${CANTEEN}/${data.code}`);
          if (res.body === 1) {
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            );
          } else {
            throw new Error('Delete failed');
          }
        } catch (error) {
          Swal.fire(
            'Error!',
            'There was a problem deleting your file.',
            'error'
          );
        }
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Canteen | Advance Dental Export Canteen Management</title>
        </MetaTags>
        <Container fluid>
          <DatatableTables title={"Canteens"} columns={columns} rows={canteenData} />

          <Modal
            isOpen={modalOpen}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="modal-title">Canteen</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModalOpen(false)}><span aria-hidden="true">×</span></button>
            </div>
            <Form
              onSubmit={(v) => {
                v.preventDefault();
                addRecord({...formData, title: v.target.title.value, code: v.target.code.value})
              }}
            >
              <ModalBody>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label imp">Canteen Name</label>
                  <input name="title" id="title" placeholder="Enter Canteen Name" required type="text" className="form-control" />
                </div>
                <div className="mb-3">
                  <label htmlFor="code" className="form-label imp">Canteen Code</label>
                  <input name="code" id="code" placeholder="Enter Canteen Code" required type="text" className="form-control" />
                </div>
              </ModalBody>
              <div className="modal-footer">
                <button type="button" className="btn btn-light" onClick={() => setModalOpen(false)}>Close</button>
                <button type="submit" className="btn btn-primary" disabled={submitBtnActive}>Submit</button>
              </div>
            </Form>
          </Modal>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default Canteens;
