import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess } from "./actions"

//Include Both Helper File with needed methods
import { post } from "helpers/api_helper"
import { LOGIN_URL } from 'helpers/url_helper' 

const loginUserCall = async data => {
  console.log(data)
  return data;
}

function* loginUser({ payload: { user, history } }) {
  console.log({user})
  try {
    const response = yield call(loginUserCall, {
      email: user.email,
      password: user.password,
    })

    localStorage.setItem("authUser", JSON.stringify(response))
    yield put(loginSuccess(response))
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
