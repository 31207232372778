import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Locations from "pages/settings/locations"
import Canteens from "pages/settings/canteens"
import PanelUsers from "pages/settings/panel_users"
import Employees from "pages/Dinner/employees"
import Guests from "pages/Dinner/guests"
import Reports from "pages/Reports"

import { useSelector, useDispatch } from "react-redux"
import { loginUser, apiError, socialLogin } from "../store/actions"



const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/settings/locations", component: Locations },
  { path: "/settings/canteens", component: Canteens },
  { path: "/settings/panel-users", component: PanelUsers },
  { path: "/employees", component: Employees },
  { path: "/guests", component: Guests },
  { path: "/reports", component: Reports },
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd }
]

export { publicRoutes, authProtectedRoutes }
