
import React from "react"
import MetaTags from 'react-meta-tags';
import {
  Container
} from "reactstrap"
import DatatableTables from "components/tables/DatatableTables";

const Reports = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Reports | Advance Dental Export Canteen Management</title>
                </MetaTags>
                <Container fluid>
                    <span>Reports page coming soon</span>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Reports;
