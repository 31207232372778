import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row
} from "reactstrap"

import logo from "../../assets/images/canteen_logo.png"

class Dashboard extends Component {

  

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Advance Dental Export Canteen Management</title>
          </MetaTags>
          <Container fluid>
            <h4>Dashboard</h4>
            <div className="row">
              <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                        <div className="row">
                          <div className="col-lg-4">
                              <div className="d-flex">
                                <div className="flex-shrink-0 me-3">
                                  <img src={logo} alt="" className="avatar-md rounded-circle img-thumbnail"/>
                                </div>
                                <div className="flex-grow-1 align-self-center">
                                    <div className="text-muted">
                                      <p className="mb-2">Welcome to Canteen Management Panel</p>
                                      <h5 className="mb-1">Canteen</h5>
                                      <p className="mb-0">Surat</p>
                                    </div>
                                </div>
                              </div>
                          </div>
                          <div className="align-self-center col-lg-4">
                              <div className="text-lg-center mt-4 mt-lg-0">
                                <div className="row">
                                    <div className="col-4">
                                      <div>
                                          <p className="text-muted text-truncate mb-2">Total Diners</p>
                                          <h5 className="mb-0">48</h5>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div>
                                          <p className="text-muted text-truncate mb-2">Employees</p>
                                          <h5 className="mb-0">40</h5>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div>
                                          <p className="text-muted text-truncate mb-2">Guests</p>
                                          <h5 className="mb-0">18</h5>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>

            <Row>
              {/* <Tasks />
              <AnalyticsBars /> */}
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Dashboard;
